import React from "react"
import { graphql } from "gatsby"

import Logo from "../components/logo"
import SEO from "../components/seo"

import "../css/style.css"

import Instagram from "../images/instagram.svg"
import Facebook from "../images/facebook.svg"

export const query = graphql`
  {
    allDatoCmsLinktree(sort: { order: ASC, fields: orden }) {
      nodes {
        id
        titulo
        link
        descripcion
        orden
      }
    }
  }
`

const Linktree = ({ data }) => {
  return (
    <>
      <Logo />
      <div className="instaname text-sm text-center text-gray-500 bg-black pb-8">
        <a
          href="https://www.instagram.com/spiritualworker/"
          target="_blank"
          rel="noopener"
        >
          @SpiritualWorker
        </a>
      </div>
      <SEO title="Linktree de Spiritual Worker" />

      <div className="w-full mx-auto bg-gray-900 pt-5 pb-10 lg:pt-20 lg:pb-20">
        <div className="w-full mx-auto">
          <div className="w-full lg:w-2/3 mt-3 lg:mt-0 mx-auto">
            {data.allDatoCmsLinktree.nodes.map(tree => (
              <div className="blogpost texts p-4 pb-3" key={tree.id}>
                <a
                  href={`${tree.link}`}
                  className="titles block text-gray-200 text-base text-center py-5 px-8 hover:text-yellow-200 bg-gray-800 rounded-full"
                  target="_blank"
                  rel="noopener"
                >
                  {tree.titulo}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Linktree
